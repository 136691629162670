<template>
    <div id="test8">
        <div class="crumbs">垃圾分类 / 违规监测</div>
        <div class="center">
            <div class="table">
                <div class="center_top">
                    <span>
                        <el-form :inline="true" class="demo-form-inline">
                            <el-form-item label="小区筛选">
                                <el-cascader :options="options"></el-cascader>
                            </el-form-item>
                            <el-form-item label="ID查询">
                                <el-input placeholder="请输入搜索ID"></el-input>
                            </el-form-item>
                            <el-form-item label="是否处罚">
                                <el-select placeholder="请选择" v-model="value">
                                    <el-option v-for="item in options1" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="日期筛选">
                                <el-date-picker v-model="value1" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit" size="medium">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="date" label="编号" width="50" :align="'center'">
                    </el-table-column>
                    <el-table-column label="上报时间" width="100" :align="'center'">
                    </el-table-column>
                    <el-table-column label="违规情况" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="投放人照片" width="90" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="province" label="垃圾桶照片" width="90" :align="'center'">
                    </el-table-column>
                    <el-table-column label="投放人视频" width="90" :align="'center'">
                    </el-table-column>
                    <el-table-column label="垃圾桶视频" width="90" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="city" label="小区" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="name" label="专管" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="address" label="投放点" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="zip" label="姓名/户号" width="100" :align="'center'">
                    </el-table-column>
                    <el-table-column label="记录ID" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="状态" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="是否处罚" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="是否公示" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="处罚内容" width="87" :align="'center'">
                    </el-table-column>
                    <el-table-column label="操作" :align="'center'">
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <span style="float: right; margin-top: 20px;margin-right: 20px;">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage4" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper" :total="100" background>
                    </el-pagination>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoTest8',

    data() {
        return {
            currentPage4: 4,
            tableData: [
            ],
            value1: '',
            options1: [{
                value: '选项1',
                label: '选项1'
            },],
            value: '',
            options: [{
                value: 'zhinan',
                label: '指南',
                disabled: true,
                children: [{
                    value: 'shejiyuanze',
                    label: '设计原则',
                    children: [{
                        value: 'yizhi',
                        label: '一致'
                    }, {
                        value: 'fankui',
                        label: '反馈'
                    }, {
                        value: 'xiaolv',
                        label: '效率'
                    }, {
                        value: 'kekong',
                        label: '可控'
                    }]
                }, {
                    value: 'daohang',
                    label: '导航',
                    children: [{
                        value: 'cexiangdaohang',
                        label: '侧向导航'
                    }, {
                        value: 'dingbudaohang',
                        label: '顶部导航'
                    }]
                }]
            }, {
                value: 'zujian',
                label: '组件',
                children: [{
                    value: 'basic',
                    label: 'Basic',
                    children: [{
                        value: 'layout',
                        label: 'Layout 布局'
                    }, {
                        value: 'color',
                        label: 'Color 色彩'
                    }, {
                        value: 'typography',
                        label: 'Typography 字体'
                    }, {
                        value: 'icon',
                        label: 'Icon 图标'
                    }, {
                        value: 'button',
                        label: 'Button 按钮'
                    }]
                }, {
                    value: 'form',
                    label: 'Form',
                    children: [{
                        value: 'radio',
                        label: 'Radio 单选框'
                    }, {
                        value: 'checkbox',
                        label: 'Checkbox 多选框'
                    }, {
                        value: 'input',
                        label: 'Input 输入框'
                    }, {
                        value: 'input-number',
                        label: 'InputNumber 计数器'
                    }, {
                        value: 'select',
                        label: 'Select 选择器'
                    }, {
                        value: 'cascader',
                        label: 'Cascader 级联选择器'
                    }, {
                        value: 'switch',
                        label: 'Switch 开关'
                    }, {
                        value: 'slider',
                        label: 'Slider 滑块'
                    }, {
                        value: 'time-picker',
                        label: 'TimePicker 时间选择器'
                    }, {
                        value: 'date-picker',
                        label: 'DatePicker 日期选择器'
                    }, {
                        value: 'datetime-picker',
                        label: 'DateTimePicker 日期时间选择器'
                    }, {
                        value: 'upload',
                        label: 'Upload 上传'
                    }, {
                        value: 'rate',
                        label: 'Rate 评分'
                    }, {
                        value: 'form',
                        label: 'Form 表单'
                    }]
                }, {
                    value: 'data',
                    label: 'Data',
                    children: [{
                        value: 'table',
                        label: 'Table 表格'
                    }, {
                        value: 'tag',
                        label: 'Tag 标签'
                    }, {
                        value: 'progress',
                        label: 'Progress 进度条'
                    }, {
                        value: 'tree',
                        label: 'Tree 树形控件'
                    }, {
                        value: 'pagination',
                        label: 'Pagination 分页'
                    }, {
                        value: 'badge',
                        label: 'Badge 标记'
                    }]
                }, {
                    value: 'notice',
                    label: 'Notice',
                    children: [{
                        value: 'alert',
                        label: 'Alert 警告'
                    }, {
                        value: 'loading',
                        label: 'Loading 加载'
                    }, {
                        value: 'message',
                        label: 'Message 消息提示'
                    }, {
                        value: 'message-box',
                        label: 'MessageBox 弹框'
                    }, {
                        value: 'notification',
                        label: 'Notification 通知'
                    }]
                }, {
                    value: 'navigation',
                    label: 'Navigation',
                    children: [{
                        value: 'menu',
                        label: 'NavMenu 导航菜单'
                    }, {
                        value: 'tabs',
                        label: 'Tabs 标签页'
                    }, {
                        value: 'breadcrumb',
                        label: 'Breadcrumb 面包屑'
                    }, {
                        value: 'dropdown',
                        label: 'Dropdown 下拉菜单'
                    }, {
                        value: 'steps',
                        label: 'Steps 步骤条'
                    }]
                }, {
                    value: 'others',
                    label: 'Others',
                    children: [{
                        value: 'dialog',
                        label: 'Dialog 对话框'
                    }, {
                        value: 'tooltip',
                        label: 'Tooltip 文字提示'
                    }, {
                        value: 'popover',
                        label: 'Popover 弹出框'
                    }, {
                        value: 'card',
                        label: 'Card 卡片'
                    }, {
                        value: 'carousel',
                        label: 'Carousel 走马灯'
                    }, {
                        value: 'collapse',
                        label: 'Collapse 折叠面板'
                    }]
                }]
            }, {
                value: 'ziyuan',
                label: '资源',
                children: [{
                    value: 'axure',
                    label: 'Axure Components'
                }, {
                    value: 'sketch',
                    label: 'Sketch Templates'
                }, {
                    value: 'jiaohu',
                    label: '组件交互文档'
                }]
            }]
        };
    },

    mounted() {

    },

    methods: {
        onSubmit() {
            console.log('submit!');
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
    },
};
</script>

<style lang="less" scoped>
#test8 {
    width: 100%;
    height: 100vh;

}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.center {
    width: 100vw;
    height: calc(100% - 31px);
    // background-color: #000;
    margin: auto;




    .center_top {
        padding-top: 10px;
        padding-bottom: 13px;
        height: 36px;
        display: flex;
        justify-content: center;

        ::v-deep .el-input__inner {
            width: 190px;
            height: 32px;
        }
    }

    .table {
        height: calc(100vh - 100px);
        overflow: auto;
    }


}
</style>